import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import { getConfig, getTranslation } from "../shared/modules/config";
import { isLocalStorageEnabled } from "../shared/modules/localStorage";

const baseClassName = "consumer-app";

const host = getConfig("host");
const shopDomain = getConfig("domain");
const isExchangesEnabled = getConfig("is_exchanges_enabled");
const isUnevenExchangesEnabled = getConfig("is_uneven_exchanges_enabled");
const isStripeEnabled = getConfig("is_stripe_enabled");
const isPaymentFlowEnabled = getConfig("is_payment_flow_enabled");
const isRefundAutomationEnabled = getConfig("is_refund_automation_enabled");
const isGiftZipEnabled = getConfig("is_gift_zip_enabled");
const isGiftReceiptEnabled = getConfig("is_gift_receipt_enabled");
const isShopNowEnabled =
  getConfig("is_shop_now_enabled") && isLocalStorageEnabled;
const isShopNowWithCreditEnabled =
  isShopNowEnabled && getConfig("is_shop_now_with_credit_enabled");
const isInAppLabelEnabled = getConfig("enable_in_app_label");
const hideReturnMethodPrice = getConfig("hide_return_method_price");
const onlyShowEstimatedRefund = getConfig("only_show_estimated_refund");
const sortExchangeVariantsByAvailability = getConfig(
  "sort_exchange_variants_by_availability",
);
const isAddressProvinceDisabled = getConfig("disable_modify_address_province");
const isNthUi = getConfig("is_nth_ui");
const isNthLoginUi = getConfig("is_nth_login_ui");
const isReturnUpsellDisabled = getConfig("is_return_upsell_disabled");
const isUserPreferencesEnabled =
  isNthUi && getConfig("is_user_preferences_enabled");
const isIncludeCurrency = getConfig("is_include_currency");
const environment = getConfig("environment");
const stripePubKey = getConfig("stripe_pub_key");
const stripeAccount = getConfig("shop_stripe_acc");
const shid = getConfig("shid");
const shopifyCollectiveEnabled = getConfig("shopify_collective_enabled");
const isReshopSmsUpdates = getConfig("is_reshop_sms_updates");
const isReshopEarlyHardApproval = getConfig("is_reshop_early_hard_approval");
const isCropItemThumbnail = getConfig("is_crop_item_thumbnail");
const showItemSku = getConfig("show_item_sku");

const shouldHideNarvarSeamlessExchangeValue = getConfig(
  "should_hide_narvar_seamless_exchange_value",
);

const userPreferencesEndpoint =
  environment === "development"
    ? "https://customer-accounts.qa04.narvar.qa"
    : "https://profile.narvar.com";

const translations = getConfig("translations") || {
  order_lookup_not_found:
    "We couldn't find that order. Make sure your order number and billing email are correct and try again.",
  order_lookup_option_return_progress: "You have a return in progress.",
  order_lookup_return_has_been_cancelled:
    "The return has been cancelled successfully.",
  order_lookup_option_cancel_return: "Cancel outstanding Return",
  order_lookup_option_different_order: "Return a Different Order",
  order_lookup_option_view_policy: "View Our Return Policy",
  order_lookup_find_another_order: "Find another order",
  order_lookup_return_window_prefix: "You can make returns on this order until",
  order_lookup_gift_title_start_return: "Start a gift return",
  order_lookup_not_gift_link: "Not returning a gift?",
  order_lookup_gift_link: "Returning a gift?",
  order_lookup_gift_start_instructions:
    "Changed your mind about a gift? We won't tell. Return your gift now for store credit or exchange it for something else.",
  order_lookup_gift_shipping_zip: "Shipping Zip Code",
  order_lookup_gift_code: "Gift Code",
  order_lookup_gift_email_helper:
    "We’ll use this to send you your return label and refund.",
  order_lookup_gift_code_helper:
    "Can’t find this? Try looking on your gift receipt, or email customer service and we’ll help you out.",
  order_lookup_title_start_return: "Start a Return",
  order_lookup_start_return_instructions:
    "Enter your order information to get started.",
  order_lookup_title_no_eligible_items:
    "No items in this order are eligible for return",
  order_lookup_submit: "Find My Order",
  choose_items_title: "What would you like to return?",
  choose_items_none_eligible: "None of these items is eligible for return.",
  choose_items_select_reason_prompt: "Select a Return Reason",
  choose_items_select_reason_prompt_exchange: "Select an Exchange Reason",
  choose_items_exchange_wizard_unavailable_title:
    "This product is unavailable for exchange.",
  choose_items_exchange_item_button_out_of_stock: "Out Of Stock",
  choose_items_exchange_wizard_unavailable_go_back: "Go Back",
  choose_items_select_subreason_prompt: "Tell us more",
  choose_items_select_quantity_prompt: "Select a Return Quantity",
  choose_items_select_quantity_prompt_exchange: "Select an Exchange Quantity",
  choose_items_quantity_label: "Select a Return Quantity",
  choose_items_comment_title: "Additional Comments",
  choose_items_add_comment: "Add Comment",
  choose_items_edit_comment: "Edit Comment",
  choose_items_comment_helper_text:
    "Please tell us in 1 or 2 sentences why you would like to return this item",
  choose_items_comment_optional_placeholder: "Leave a comment (optional)",
  choose_items_comment_required_placeholder: "Leave a comment (required)",
  choose_items_comment_continue_label: "Continue",
  choose_items_return_item_button_label: "Return Item",
  choose_items_exchange_item_button_label: "Exchange Item",
  choose_items_exchange_wizard_title: "Select a new item",
  choose_items_return_button_label: "Return %{quantity} %{label}",
  choose_items_exchange_button_label: "Exchange %{quantity} %{label}",
  choose_items_exchange_variant_option_unavailable_label:
    "– Not available in selected",
  choose_items_return_and_exchange_button_label:
    "Return & Exchange %{quantity} %{label}",
  choose_items_choose_exchange_variant_label_general: "Choose",
  choose_items_exchange_one_item_button_label: "Exchange 1 Item",
  choose_items_choose_exchange_variant_not_available: "– not available",
  choose_items_choose_exchange_variant_not_available_in_selected:
    "– not available in selected options",
  choose_items_cancel_exchange_button_label: "Cancel Exchange",
  choose_items_find_another_item_button_label: "Find Another Item",
  choose_items_back_to_details_label: "Back to Details",
  choose_items_find_another_item_title: "Find Another Item",
  choose_items_exchange_search_placeholder: "Search by keyword",
  choose_items_exchange_showing_results_label: "Showing %{count} results",
  choose_items_next_button_label: "Next",
  choose_method_title: "How would you like to return it?",
  choose_method_no_intersection:
    "No valid return methods for the selected items. Please try with a different combination of items.",
  choose_method_card_in_store_title: "Visit Our Store",
  choose_method_card_in_store_subtitle:
    "Come to our store to return it in person and get a quick refund",
  choose_method_card_ship_on_your_own_title: "Mail It",
  choose_method_card_ship_on_your_own_subtitle:
    "Securely pack and seal your return, and drop it off at your local shipping center.",
  choose_method_substep_in_store_title: "Find a Store",
  choose_method_substep_in_store_form_find_stores_near:
    "Search for locations near",
  choose_method_substep_in_store_locations_near: "Locations near",
  choose_method_card_by_mail_title: "Drop Off at the Post Office",
  choose_method_card_by_mail_subtitle:
    "Securely pack and seal your return, and drop it off at your local post office.",
  choose_method_next_button_label: "Next",
  choose_method_substep_next_button_label: "Next",
  mailing_address_section_title: "Mailing Address",
  mailing_address_form_title: "Change Mailing Address",
  mailing_address_form_address_line_1: "Street Address",
  mailing_address_form_address_line_2: "Address Line 2",
  mailing_address_form_city: "City",
  mailing_address_form_state: "State",
  mailing_address_form_zip: "Zip Code",
  mailing_address_form_first_name: "First Name",
  mailing_address_form_last_name: "Last Name",
  gift_exchange_mailing_address_title: "Where should we send your new items",
  gift_exchange_mailing_address_save: "Confirm Address",
  gift_exchange_mailing_address_change: "change",
  home_pickup_mailing_address_title: "Where are you shipping from?",
  bad_mailing_address_title: "Please update your address",
  bad_mailing_address_submit: "Confirm Address and Submit Again",
  return_review_title: "Return Details",
  return_review_comments_title: "Comments",
  return_review_keep_item_disclaimer:
    "You do not need to return this item please keep it or donate it.",
  return_review_exchange_item_disclaimer: "Exchanging for %{name}",
  return_review_estimated_refund: "Estimated Refund",
  return_review_items: "Items",
  return_review_returning: "Returning",
  return_review_receiving: "Receiving",
  return_review_return_method: "Return Method",
  return_review_subtotal: "Subtotal",
  return_review_original_purchase_shipping: "Original Shipping Price",
  return_review_total_tax: "Total Tax",
  return_review_charge_total: "Total",
  return_review_disclaimer:
    "Actual refund amount is subject to applied discounts, tax, shipping costs, and item condition.",
  return_review_original_payment_method: "Original Payment Method",
  return_review_submit_return: "SUBMIT MY RETURN",
  return_review_error_contact_retailer: "Please contact the retailer.",
  return_review_item_quantity: "Quantity",
  shopnow_outstanding_credit_alert:
    "You have %{amount} shop now credit outstanding.",
  shopnow_cancel_session_confirm_message:
    "This will cancel outstanding shop now sessions. Would you like to continue?",
  shopnow_cancel_session_success:
    "Your outstanding shop now sessions have been cancelled. The remaining credit will be issued back.",
  shopnow_opt_in_title: "Want to use your return credit to shop now?",
  shopnow_opt_in_description:
    "We’ll automatically add a credit to your cart to make sure you get the items you want even faster.",
  shopnow_opt_in_incentive_description:
    "We’ll even add %{amount} to your credit!",
  shopnow_opt_in_yes: "Shop Now With %{amount}",
  shopnow_opt_in_no: "Get a Refund For %{amount}",
  choose_refund_method_gift_card: "%{shop_name} Gift Card",
  choose_refund_method_original_payment: "Original Form of Payment",
  choose_refund_method_incentive_amount: "+ %{amount} Reward",
  choose_refund_method_next_button_label: "Next",
  choose_items_shopnow_remarks:
    "*You will be able to shop for your new items after completing your return selections",
  choose_items_choose_exchange_variant_shopnow_action_description:
    "Not seeing what you want?",
  choose_items_choose_exchange_variant_shopnow_action_link:
    "Shop with credit instead",
  away: "away",
  email: "Email",
  cancel: "cancel",
  color: "color",
  edit: "edit",
  free: "free",
  order_number: "order number",
  quantity: "quantity",
  save: "save",
  search: "search",
  size: "size",
  zipcode: "zipcode",
  exit: "Exit",
  order_lookup_supplementary_text1: "",
  order_lookup_supplementary_text2: "",
  return_confirmation_subtitle_label_retry_enqueued:
    "We've sent a confirmation email with next steps for your return to %{email}. We will email you your shipping label once it is ready for printing.",
};

const countryCodes = getConfig("country_codes");

const countryNamesByCode = mapValues(
  keyBy(countryCodes, "country_code"),
  "country",
);

export const getAddressFormConfig = overrides => ({
  firstName: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_first_name"),
  },
  lastName: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_last_name"),
  },
  address1: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_address_line_1"),
  },
  address2: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_address_line_2"),
  },
  city: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_city"),
  },
  province: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_state"),
  },
  zip: {
    type: "text",
    value: "",
    label: getTranslation("mailing_address_form_zip"),
  },
  countryCode: { type: "text", value: "" },
  ...overrides,
});

const addressDefaults = Object.entries(getAddressFormConfig({})).reduce(
  (acc, [key, data]) => ({ ...acc, ...{ [key]: { value: data.value } } }),
  {},
);

export const config = {
  baseClassName,
  countryCodes,
  countryNamesByCode,
  isExchangesEnabled,
  isUnevenExchangesEnabled,
  isStripeEnabled,
  isPaymentFlowEnabled,
  isRefundAutomationEnabled,
  isGiftZipEnabled,
  isGiftReceiptEnabled,
  isShopNowEnabled,
  isShopNowWithCreditEnabled,
  isInAppLabelEnabled,
  hideReturnMethodPrice,
  onlyShowEstimatedRefund,
  sortExchangeVariantsByAvailability,
  isAddressProvinceDisabled,
  isNthUi,
  isNthLoginUi,
  isReturnUpsellDisabled,
  isUserPreferencesEnabled,
  isIncludeCurrency,
  isReshopSmsUpdates,
  isReshopEarlyHardApproval,
  isCropItemThumbnail,
  showItemSku,
  shouldHideNarvarSeamlessExchangeValue,
  userPreferencesEndpoint,
  environment,
  stripePubKey,
  stripeAccount,
  translations,
  host,
  shopDomain,
  shid,
  shopifyCollectiveEnabled,
  getAddressFormConfig,
  addressDefaults,
  // dynamic value, value exists after receiving `nvo_config_liquid` iframe message
  get logoImg() {
    return getConfig("logo_img");
  },
  get shopName() {
    return getConfig("shop_name");
  },
  get shopUrl() {
    return getConfig("shop_url");
  },
};
